//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CitiesListTable from '@/modules/cities/components/cities-list-table.vue';
import CitiesFormModal from '@/modules/cities/components/cities-form-modal.vue';

import { mapGetters, mapActions } from 'vuex';
import { CitiesPermissions } from '@/modules/cities/cities-permissions';
// import * as firebase from 'firebase';

export default {
  name: 'app-cities-list-page',

  components: {
    [CitiesListTable.name]: CitiesListTable,
    [CitiesFormModal.name]: CitiesFormModal,
  },
  data() {
    return {
      modalVisible: false,
      dialogVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToCreate() {
      return new CitiesPermissions(this.currentUser).create;
    },
  },

  // async created() {
  //   await firebase.firestore().collection('city').onSnapshot(async querySnapshot => {
  //      console.log(`Received query snapshot of size ${querySnapshot.size}`);
  //     this.doFilterInBackground();
  //   }, err => {
  //      console.log(`Encountered error: ${err}`);
  //   });
  // },
  async mounted() {
    await this.doFetchCities()
  },
  methods: {
    ...mapActions({
      doFetchCities: 'citiesRegions/list/doFetchCities',
    }),
    doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
  
    async doFilter() {
      // filterSchema.cast(this.model);
      const filter = { citiesType: 'branch' }
      return this.doFetch({
        filter,
      });
    },
    async doFilterInBackground() {
      const filter = { citiesType: 'branch' }
      return this.doFetchInBackground({
        filter,
      });
    },

  },

};
